import React from 'react';
import { AuthState, useIdentify } from '@hungryroot/customer';
import { Footer as HungryrootFooter } from '@hungryroot/footer';
import { useDefaultValue } from '../../hooks/use-default-value';
import { islandRoot } from '../IslandRoot';

export const FooterCustomerLinksIsland = islandRoot(FooterCustomerLinks);

function FooterCustomerLinks() {
  const auth = useIdentify();
  const authValue = useDefaultValue(auth, {
    authState: AuthState.isLoading,
  });

  return <HungryrootFooter.CustomerLinks customerAuthState={authValue} />;
}
